import React from "react";
import { base64image } from "../utils";
import yourWaifu from "../ressources/yourwaifu.png";
import { Button } from "@blueprintjs/core";

export const MyGirl = ({
  girl: { image },
  keepGirl,
  showSkipButton,
  showDownloadButton,
  showRestartButton,
  downloadGirl,
  restart,
  loading,
  girlLoading
}) => {
  return (
    <div>
      <div className="my-girl">
        <img className={`your-waifu`} src={yourWaifu} />
        <img style={{width: 512, height: 512}} className={`my-girl-image ${girlLoading ? "my-girl-loading" : "my-girl-loaded"}`} src={base64image(image)} />
      </div>
      {showSkipButton ? (
        <div className="keep-button">
          <Button
            onClick={keepGirl}
            loading={loading}
            minimal
            fill
            icon="heart"
            text="Keep this girl and skip this step"
          />
        </div>
      ) : (
        undefined
      )}
      {showDownloadButton ? (
        <div className="download-button">
          <Button
            onClick={downloadGirl}
            loading={loading}
            minimal
            fill
            icon="download"
            text="Download this girl"
          />
        </div>
      ) : (
        undefined
      )}
      {showRestartButton ? (
        <div className="restart-button">
          <Button
            onClick={restart}
            loading={loading}
            minimal
            fill
            icon="heart-broken"
            text="Restart"
          />
        </div>
      ) : (
        undefined
      )}
    </div>
  );
};
