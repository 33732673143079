import React from "react"
import { base64image } from "../utils";
import AtvImg from "./AtvImg";

export const Cards = ({ girls, onClick, className }) => {
    return (
        <div className={`cards-container ${className}`}>
            {
                girls.map((g, i) => (<Card key={i} girl={g} onClick={onClick} />))
            }
        </div>
    )
}


const Card = ({ girl, onClick }) => {
    const {image} = girl
    return (
        <div onClick={() => onClick(girl)} className="girl">
            <AtvImg
                layers={[base64image(image)]}
                staticFallback={base64image(image)}
                isStatic={false}
                style={{ width: 150, height: 150 }}
            />
        </div>
    )
}