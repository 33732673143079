import React from "react";
import { save } from "save-file";
import { CSSTransition } from "react-transition-group";
import Tour from "reactour";
import {
  Spinner,
  Button,
  Intent,
  Callout,
  Dialog,
  Classes,
  Tooltip,
  NonIdealState
} from "@blueprintjs/core";
import "normalize.css/normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "antd/dist/antd.css";
import "./App.css";
import { Cards } from "./components/Cards";
import { MyGirl } from "./components/MyGirl";
import { Steps } from "antd";
import icon from "./ressources/icon.png";
import { IconNames } from "@blueprintjs/icons";

const { Step } = Steps;

const tutorialSteps = [
  {
    selector: ".steps",
    content:
      "Creating a waifu is easy! Here you can see the four steps you need to go through"
  },
  {
    selector: ".cards-container",
    content:
      "Use to grid to guide the alogrithm to the desired girl. Click on the one you like later!"
  },
  {
    selector: ".refresh-button",
    content:
      "Use this button to refresh the grid if you are not satisfied with the current girls"
  }
];

const BASE_API = process.env.REACT_APP_BASE_API
  ? process.env.REACT_APP_BASE_API
  : "http://glass:8008";


function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(document.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
class App extends React.Component {
  state = {
    tutorialModal: false,
    tutorial: false,
    step: 0,
    loading: true,
    error: false,
    wrongToken: false,
    girlLoading: true,
    girls: [],
    girl: undefined
  };
  async fetchStep(step) {
    if(step === 4){
      this.setState({
        step,
        loading: false,
      });
      return
    }
    try {
      await this.setState({
        loading: true,
        step
      });
      const res = await fetch(`${BASE_API}/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          step,
          currentGirl: this.state.girl ? this.state.girl.seeds : undefined
        })
      });
      const { newGirls } = await res.json();
      this.setState({
        step,
        loading: false,
        girls: newGirls
      });
    } catch (e) {
      this.setState({
        error: true
      });
    }
  }
  async selectGirl(girl) {
    const { step } = this.state;
    await this.setState({
      girl
    });
    await Promise.all([this.getBigGirl(), this.fetchStep(step + 1)]);
  }
  async getBigGirl() {
    const { girl } = this.state;
    await this.setState({
      girlLoading: true
    });
    const res = await fetch(`${BASE_API}/generate_big`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        currentGirl: girl.seeds
      })
    });
    const { girl: girlImage } = await res.json();
    this.setState({
      girlLoading: false,
      girl: {
        ...girl,
        image: girlImage
      }
    });
  }
  async keepGirl() {
    const { step } = this.state;
    await this.fetchStep(step + 1);
  }
  finishTutorial() {
    this.setState({
      tutorial: false,
      tutorialModal: false
    });
    localStorage.setItem("tutorial_done", true);
  }
  startTutorial() {
    this.setState({
      tutorial: true,
      tutorialModal: false
    });
  }
  async downloadGirl() {
    const {
      girl: { image }
    } = this.state;
    await save(image, "waifu.png");
  }
  restart() {
    this.setState({
      step: 0,
      girl: undefined
    });
    this.fetchStep(0);
  }
  componentDidMount() {
    if(!(getUrlParameter("token") == "waifubeta")){
      this.setState({
        wrongToken: true,
      });
      return
    }
    if (!localStorage.getItem("tutorial_done")) {
      this.setState({
        tutorialModal: true
      });
    }
    this.fetchStep(0);
  }

  render() {
    const {
      loading,
      error,
      wrongToken,
      girls,
      girl,
      girlLoading,
      step,
      tutorial,
      tutorialModal
    } = this.state;
    if (wrongToken) {
      return (
        <div className="App">
          <CSSTransition
            in={true}
            timeout={1000}
            appear
            classNames="cross-fade"
          >
            <NonIdealState
              icon={IconNames.ERROR}
              title={`This product is not ready yet!`}
              description={`We are working hard on releasing Waifu Factory. Please be patient. If you are supposed to have an access, contact someone at Sizigi.`}
            />
          </CSSTransition>
        </div>
      );
    }
    if (error) {
      return (
        <div className="App">
          <CSSTransition
            in={true}
            timeout={1000}
            appear
            classNames="cross-fade"
          >
            <NonIdealState
              icon={IconNames.ERROR}
              title={`We are having some trouble generating your waifus`}
              description={`Please try again later! Our machines may be overloaded.`}
            />
          </CSSTransition>
        </div>
      );
    }
    if (step === 4) {
      return (
        <div className="App">
          <CSSTransition
            in={true}
            timeout={1000}
            appear
            classNames="cross-fade"
          >
            <MyGirl
              girl={girl}
              showDownloadButton
              downloadGirl={() => this.downloadGirl()}
              showRestartButton
              girlLoading={girlLoading}
              restart={() => this.restart()}
            />
          </CSSTransition>
          <a href="https://sizigistudios.com/" target="_blank">
            <img src={icon} className="sizigi" />
          </a>
        </div>
      );
    }
    return (
      <>
        <div className="App">
          <div className="column">
            <Steps className="steps" progressDot current={step}>
              <Step
                title="Start here"
                description="Choose your initial waifu"
              />
              <Step
                title="Color palette"
                description="Tune the color palette"
              />
              <Step title="Details" description="Fine tune the details" />
              <Step
                title="Pose"
                description="Finish with your favorite pose!"
              />
            </Steps>
            <div className={`container ${loading ? "loading" : ""}`}>
              {
                <Container
                  loading={loading}
                  girlLoading={girlLoading}
                  girls={girls}
                  girl={girl}
                  onClick={girl => this.selectGirl(girl)}
                  keepGirl={() => this.keepGirl()}
                />
              }
            </div>
          </div>
          <Button
            className="refresh-button"
            intent={Intent.PRIMARY}
            large
            text="Refresh the grid"
            icon="refresh"
            onClick={() => this.fetchStep(step)}
          />
          <TutorialModal
            isOpen={tutorialModal}
            onClose={() => this.finishTutorial()}
            startTutorial={() => this.startTutorial()}
          />
        </div>
        <Tour
          steps={tutorialSteps}
          isOpen={tutorial}
          disableInteraction
          rounded={5}
          onRequestClose={() => this.finishTutorial()}
          lastStepNextButton={
            <Button minimal text="Finish the tutorial" icon="ninja" />
          }
        />
        <a href="https://www.amazon.com/Cake-Duel-Light-Hearted-Bluffing-Game/dp/B07HHKCC6B/ref=sr_1_1?keywords=cake+duel&qid=1561165768&s=gateway&sr=8-1" target="_blank">
          <img src={icon} className="sizigi" />
        </a>
      </>
    );
  }
}

const TutorialModal = ({ isOpen, onClose, startTutorial }) => (
  <Dialog
    icon="info-sign"
    isOpen={isOpen}
    onClose={onClose}
    title="You are new here"
  >
    <div className={Classes.DIALOG_BODY}>
      <p>
        <strong>It looks like you are new to Waifu Factory!</strong>
      </p>
      <p>
        We built a very quick tutorial to help you get the hang of the
        interface. You'll be able to create your dream waifu in no time ;)
      </p>
      <p>
        <strong>A Sizigi Studios project</strong>
      </p>
    </div>
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Tooltip content="Are you sure you want to skip the tutorial?">
          <Button onClick={onClose}>Close</Button>
        </Tooltip>
        <Button intent={Intent.PRIMARY} onClick={startTutorial}>
          Start the tutorial!
        </Button>
      </div>
    </div>
  </Dialog>
);

const Container = ({
  loading,
  girls,
  girl,
  onClick,
  keepGirl,
  girlLoading
}) => {
  return (
    <>
      {girl ? (
        <MyGirl
          girl={girl}
          keepGirl={keepGirl}
          showSkipButton
          loading={loading}
          girlLoading={girlLoading}
        />
      ) : (
        undefined
      )}
      <div className="stacked">
        <CSSTransition
          in={loading}
          timeout={1000}
          classNames="cross-fade"
          unmountOnExit
        >
          <div
            className="loading-callout stacked-1"
            style={{ height: 675, width: 675 }}
          >
            <h2 style={{ color: "rgb(0,0,0,0.6)" }}>Generating your waifus</h2>
            <Spinner size={Spinner.SIZE_LARGE} />
          </div>
        </CSSTransition>
        <CSSTransition
          in={!loading}
          timeout={1000}
          classNames="cross-fade"
          unmountOnExit
        >
          <Cards className="stacked-2" girls={girls} onClick={onClick} />
        </CSSTransition>
      </div>
    </>
  );
};

export default App;
